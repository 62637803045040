<script>
import { bank_list } from "@/api/payment";
import { channel_selection } from "@/api/recharge";
import { offline_payment_order } from "@/api/recharge";
import Clipboard from "clipboard";

export default {
    data() {
        return {
            setAt:true,         //防止重复提交
            options: [],          //付款银行列表
            optionsTrue:false,    //付款   银行列表     隐藏、出现
            options1True:false,   //付款   方式列表     隐藏、出现
            sourceType: { id: null, method: this.getresult, name: getWord('fill_bank3') },
            sourceType1: { id: null, method: this.getresult1, name: getWord('fill_transfer_channel') },
            options1: [           //付款方式
                {
                    id: "1",
                    name: getWord(['online_bank', 'transfer']),
                    method: this.getresult1,
                },
                {
                    id: "2",
                    name: getWord('bank_teller'),
                    method: this.getresult1,
                },
                {
                    id: "3",
                    name: getWord('mobile_banking'),
                    method: this.getresult1,
                },
                {
                    id: "4",
                    name: getWord('atm'),
                    method: this.getresult1,
                }
            ],
            payList:[],             //收款账号列表


            payee: "",             //收款人
            atAccount_num:'',      //选中的收款账号

            bank: "",              //支付银行
            pname: "",             //汇款人
            pay_price: "",         //汇款金额
            pay_type: "",          //汇款方式
            remark:null,
        };
    },
    components: {
        UnionpayTEMPLATE1: () => import('./UnionpayTEMPLATE1.vue'),
    },
    methods: {
        getresult: function(val, inx) {
            this.sourceType = val;
            this.bank = this.options[inx].id;
        },
         getresult1: function(val, inx) {
            this.sourceType1 = val;
            this.pay_type = this.options1[inx].id;
        },
        //复制至黏贴板
        async copyText() {
            let clipboard = new Clipboard(".YHKFZbutton");
            clipboard.on("success", function(e) {
                if (clipboard) {
                    clipboard.destroy();
                }
            });
            app.Mint.Toast(getWord('clipboard_done'));
        },
        //获取银行卡列表
        getbank() {
            bank_list()
                .then(result => {
                    if (result.data.code == "FAILUE") {
                        app.Mint.Toast(result.data.msg);
                    } else if (result.data.code == "SUCCESS") {
                        //付款银行列表
                        let a = result.data.result;
                        for(var i=0;i<a.length;i++){
                                this.options[i] = a[i];
                                this.options[i].name = this.options[i].bank;
                                this.options[i].method=this.getresult;
                        }
                    }
                })
        },

        //获取收款卡号列表
        myrecharge() {
            channel_selection("1")
                .then(result => {
                    if (result.data.code == "FAILUE") {
                        app.Mint.Toast(result.data.msg);
                    } else if (result.data.code == "SUCCESS") {
                        this.payList = result.data.result;
                        this.yhkWidth(this.payList.length);
                        if(this.payList.length>0){
                                this.YHKselect(this.payList[0]);
                                let _this = this;
                                setTimeout(function(){
                                    if(_this.payList.length>0){
                                            let lab = document.getElementById('label0');
                                            lab.click();
                                    }
                                },200);
                        }
                    }
                })
        },
        //提交申请
        commit() {

            if(this.setAt != true){
                    return;
            }else{
                    this.setAt = false;
            }
            if(this.atAccount_num == '' || this.pname == null || this.pname == undefined){
                    app.Mint.Toast(getWord('confirm_and_transfer2'));
                    return;
            }else if(this.bank == '' || this.bank == null || this.bank == undefined){
                    app.Mint.Toast(getWord('fill_bank3'));
                    return;
            }else if(this.pname == '' || this.pname == null || this.pname == undefined){
                    app.Mint.Toast(getWord(['fill', 'username3']));
                    return;
            }else if(this.pay_price == '' || this.pay_price == null || this.pay_price == undefined){
                    app.Mint.Toast(getWord(['fill', 'transfer2', 'amount_of_betting']));
                    return;
            }else if(this.pay_type == '' || this.pay_type == null || this.pay_type == undefined){
                    app.Mint.Toast(getWord('fill_transfer_channel'));
                    return;
            }

            offline_payment_order(
                "1",
                this.pname,           //汇款人
                this.pay_price,       //汇款金额
                this.atAccount_num,   //收款账号
                this.payee,           //收款人
                this.pay_type,        //汇款方式,id
                this.bank             //支付银行,id
            ).then(result => {
                    if (result.data.code == "FAILUE") {
                        app.Mint.Toast(result.data.msg);
                    } else if (result.data.code == "SUCCESS") {
                        this.$router.push({ path: "/money/payment_page/"+ this.pay_price });
                    }else{
                        app.Mint.Toast(result.data.msg);
                    }
                })
                this.setAt = true;
            },

            yhkWidth(leng){
                    let YHKWid = document.getElementById("YHKWid");
                    let num = leng;
                    if(parseInt(num) == 0){
                        app.Mint.Toast(getWord('no_support'));
                    }else if(parseInt(num) == 1){
                        YHKWid.style.width = '6.2rem';
                        YHKWid.style.overflow = 'hidden';
                        YHKWid.style.marginLeft = '0.3rem';
                    }else{
                        // YHKWid.style.width = (num*6.3)+"rem";
                    }
            },

            YHKselect(it){
                    this.payee = it.payee;
                    this.atAccount_num = it.account_num;
            }

    },
    mounted() {
        this.getbank();
        this.remark=JSON.parse(window.localStorage.getItem('channelsData'))[0].underline_remark;
    },
    provide: function(){
        return {
            'myrecharge': this.myrecharge,
            'YHKselect': this.YHKselect,
            'commit': this.commit,
        }
    },
};
</script>

<template>
    <UnionpayTEMPLATE1 
        :sourceType="sourceType"
        :pay_price="pay_price"
        :sourceType1="sourceType1"
        :pname='pname'
        :payList='payList'
        :remark='remark'
        :options="options"
        :optionsTrue='optionsTrue'
        :options1='options1'
        :options1True='options1True'
    />
</template>

<style lang="scss" scoped type="text/css">

</style>

