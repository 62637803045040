import { service } from '../CONF.js'

//获取开户银行
export function bank_list() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/Main/Bank/search',{
                params: {
                    authFree: true
                },
            })
        }))
    })
}